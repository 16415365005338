import React from 'react';

import Layout from '@src/components/layout';
import { pages } from '@src/constants/pages';
import Challenge from '@src/components/shared/challenge';
import Gallery from '@src/components/shared/gallery';
import { events } from '@src/constants/gaEvents';
import { customEvent } from '@src/helpers/gtag';

const Services = () => (
  <Layout pageName={pages.services.name}>
    <div className="with-bottom-decoration">
      <h1 className="h-center">Services</h1>
      <div className="subheading">Electronic manufacturing services</div>
    </div>
    <section className="c-content-centered h-pt-40 h-pb-40">
      <h3>Electronic Product Design &amp; New Product Introduction (NPI)</h3>
      <p>
        ADCO can perform specific electronic design and manufacturing
        engineering services to complement your in-house design engineering
        staff or recommend one of our full service design and development
        partners. We are affiliated with a team of engineers who have extensive
        knowledge of electronic product that are wireless as well as controlled
        by electronics.
      </p>
      <p>
        ADCO has a team approach to New Product Introduction (NPI). Each new
        assembly is introduced into the manufacturing environment after careful
        review by all disciplines involved in the production of the circuit
        board assembly or box build. We accomplish this by utilizing a detailed
        checklist that takes into account all of the manufacturing and planning
        controls that we have developed in our decades of experience. This is
        our Advanced Planning and Quality Production (APQP) process.
      </p>
      <p>
        Then once released for manufacturing and test we have an NPI team that
        tracks the first build through the plant. Each operation is reviewed in
        accordance with an NPI checklist. From this we take a lessons learned
        approach to advancing product documentation and verifying that we will
        ship a flawless product. We provide customer feedback and may propose
        changes in design to ensure that future product launches and production
        ramps are done as cost effectively as possible.{' '}
        <strong>
          Our goal is to help customers understand the cost possibilities in
          order to be more successful and gain market share.
        </strong>
      </p>
      <h3 className="h-mt-40">Rapid Prototyping Circuit Board Assemblies</h3>
      <p>
        To service customers with new circuit board designs ADCO has a rapid
        prototyping process. We know how important it is to you to achieve
        project milestone dates and have developed an internal prototyping team
        to deliver completed assemblies within 5 business days of material
        availability. Prototypes can be assembled using customer supplied parts
        and boards or we can quote the materials for a total turnkey solution.
      </p>
      <p>
        When you engage with us for your prototypes we also guarantee the
        delivery or will give you a refund on the assembly portion of the build.
        Your circuit board assemblies will be manufactured using our high speed
        automated lines so you can be secure in the knowledge that your
        prototypes will meet IPC-610 Class II or III requirements.
      </p>
      <h3 className="h-mt-40">Surface Mount PCB Assembly</h3>
      <p>
        ADCOproto has 3 high speed Yamaha surface mount (SMT) lines. These
        new lines represent the 4th generation of SMT equipment that ADCO has
        become expert at implementing. With over 30 years of experience in
        contract electronic manufacturing we have been placing surface mount PCB
        assembly devices almost since they were invented! We have extensive
        experience in placing 01005 chip sizes, QFN&apos;s, and Micro
        BGA&apos;s.
      </p>
      <p>
        We also manufacture a large number of different electronic assemblies
        that are either through hole (TH) or mixed technology assemblies.
        ADCO&apos;s automatic though hole axial and radial equipment can meet
        any high volume requirement and our IPC certified manufacturing
        personnel will manually assemble any additional parts that are not
        machine placed or assembled.
      </p>
      <p>
        We are always looking for challenges, so if your company has a
        particularly complicated PCB assemblies — that is our sweet spot. As
        part of our normal process, ADCO&apos;s manufacturing engineers will
        detail a control plan that will exceed IPC Class 3 requirements.
      </p>
      <h3 className="h-mt-40">
        Box Build / System Integration / Module Assembly
      </h3>
      <p>
        From our inception in 1981 ADCO has done full system assembly. We have
        deep experience in manufacturing complex control systems comprised of
        multiple circuit board assemblies and mechanical components. For testing
        these systems we can develop and maintain custom functional test
        equipment including RF applications. To further support system
        integration requirements, we have capabilities in burn in and ESS
        testing.
      </p>
      <p>
        If your need is for module assembly we will develop a work cell that is
        optimized for a production rate that meets your volume requirements.
        Manufacturing engineers incorporate flexible robotics into the cell to
        achieve cost targets that are competitive with offshore numbers.
      </p>
      <p>
        We also provide direct order fulfillment from our warehouse. ADCO will
        make to stock and then ship to your customer as needed. Give us a call
        to explore how our advanced ERP system enables us to customize a
        logistics solution that works with your business model.
      </p>
      <h3 className="h-mt-40">Test &amp; Environmental Stress Screening</h3>
      <p>
        ADCO has designed and implemented test processes since our inception
        over 33 years ago. Our abilities in this area are a clear
        differentiator. Our engineers can design a test plan including hardware
        and software to meet customer specifications, or can accept and maintain
        customer supplied test equipment.
      </p>
      <p>
        For low volume circuit board assemblies a <strong>flying probe</strong>{' '}
        tester can be programmed to meet your requirements, or for higher
        volume, an <strong>In-Circuit HP 3070</strong> can be utilized. We also
        design semi-custom test hardware using rack mounted National Instruments
        test platforms running Labview software or can design function-specific
        test boxes. You can be certain that the test equipment will perform as
        intended by reviewing our test validation and test coverage report that
        is delivered with each project.
      </p>
      <p>
        For <strong>Environmental Stress Screening (ESS)</strong>, we have
        temperature chambers that meet aerospace requirements -30C to +150C at a
        temperature gradient of 5 degrees C per minute. Very soon with a new
        equipment delivery, ADCO will be installing vibration gear for
        additional in-house capability.
      </p>
      <h4>Some of ADCO designed test systems include:</h4>
      <ul>
        <li>Aerospace control assembly functional test racks</li>
        <li>Automotive control assembly functional test fixtures</li>
        <li>LED light assembly testers</li>
        <li>
          Semiconductor fabrication tool controller stand alone bed of nails
          fixtures
        </li>
      </ul>
      <h3 className="h-mt-40">PCB Printed Circuit Board Assembly</h3>
      <h4>Leading Edge Information Systems for Efficiency &amp; Speed</h4>
      <ul>
        <li>ADCOproto.com web based prototype quoting and order processing</li>
        <li>Electronic documentation</li>
        <li>Class leading ERP System (EPICOR)</li>
        <li>Auto – Release PO System with major distributors</li>
        <li>
          Quality Management System (QMS) with dash boards supporting Key
          Process Indicators (KPI)
        </li>
        <li>Automated Stockroom?</li>
      </ul>
      <h4>Advanced Manufacturing Process&lsquo;s for Quality &amp; Reliability</h4>
      <ul>
        <li>ISO9001, AS9100, and TS16949 certifications</li>
        <li>In house training</li>
        <li>Class II and III assembly standards</li>
        <li>Large investment in modern equipment</li>
        <li>3D AOI / Automated Optical Inspection</li>
        <li>X-Ray</li>
        <li>Robotic assembly</li>
        <li>Conformal coating &amp; potting</li>
        <li>System / Box Builds</li>
        <li>
          Extensive test capabilities (functional, ICT, flying-probe, ESS)
        </li>
        <li>Dedicated manufacturing cells for volume system builds</li>
      </ul>
      <h4>
        Customer Focused Philosophy resulting in Value &amp; Responsiveness
      </h4>
      <ul>
        <li>Strategic supply chain relationships</li>
        <li>Continuous improvement</li>
        <li>Customer focused Program Managers and procurement</li>
        <li>Manufacturing Engineers with over 75 years combined experience</li>
        <li>Electrical Engineers to develop &amp; support test platforms</li>
      </ul>
    </section>
    <Gallery />
    <Challenge
      onStartQuotingClick={customEvent(events.certificationsStartQuoting())}
      onContactUsClick={customEvent(events.certificationsContactUs())}
    />
  </Layout>
);

export default Services;
